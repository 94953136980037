/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
import React from 'react';
import {
    DateInput,
    Edit,
    ImageField,
    ImageInput,
    minValue,
    number,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {Title} from "../shared/Title";
import {CustomToolbarEdit} from "../shared/CustomToolbarEdit";
import RichTextInput from 'ra-input-rich-text';
import {MAX_BLOG_POST_IMAGE_UPLOAD_SIZE} from "../constants/UPLOAD_SIZE";


export const BlogPostEdit = (props) => (
    <Edit {...props} title={<Title name={'Blog Post'}/>}>
        <SimpleForm toolbar={<CustomToolbarEdit/>}>
            <TextInput disabled source="id"/>
            <TextInput disabled source="pathToPicture"/>
            <TextInput source="title" validate={[required()]}/>
            <RichTextInput source="text" validate={[required()]}/>
            <TextInput source="author" validate={[required()]}/>
            <NumberInput source="likes" inputProps={{min: 0}} defaultValue={0}
                         validate={[required(), number(), minValue(0)]}/>
            <DateInput source="createdDateTime" validate={[required()]}/>
            <ImageInput source="imageBase64"
                        disabled={true}
                        options={{acceptedFiles: []}}
                        maxSize={MAX_BLOG_POST_IMAGE_UPLOAD_SIZE}
                        label="New food picture" accept="image/*">
                <ImageField
                    source="pathToPicture"
                    title="title"/>
            </ImageInput>
            <ImageField source="pathToPicture" title="title" label='Old picture'/>
        </SimpleForm>
    </Edit>
);