/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
export const API_URL = process.env.REACT_APP_API_URL;

export const USER_CHANGE_PASSWORD_ENDPOINT = API_URL + '/users/change-password';
export const MENU_SHOPPING_LIST_ENDPOINT = API_URL + '/menus/shopping-list';
export const MENU_ITINERARY_ENDPOINT = API_URL + '/menus/itinerary';
export const CURRENT_USER_ENDPOINT = API_URL + '/users/current';

