/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
import {isEmpty} from 'lodash';
import {serializeFilter} from "./filterMapper";
import {API_URL} from "../constants/ENDPOINTS";


const convertDataProviderRequestToHTTP = (type, resource , params) => {
     switch (type) {
        case GET_MANY_REFERENCE:
        case GET_MANY:{
            return { url: `${API_URL}/${resource}` };
        }
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const orderMark = order === 'ASC' ? '' : '-';
            const query = {
                sorts: orderMark + field,
                page: JSON.stringify(page),
                pageSize: JSON.stringify(perPage),
                filters: isEmpty(params.filter) ? null : serializeFilter(params.filter),
            };
            return {
                url: `${API_URL}/${resource}?${stringify(query)}`,
                options: { method: 'GET' },
            };
        }
        case GET_ONE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: { method: 'GET' },
            };
        case UPDATE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: { method: 'PUT', body: JSON.stringify(params.data) },
            };
        case CREATE:
            return {
                url: `${API_URL}/${resource}`,
                options: { method: 'POST', body: JSON.stringify(params.data) },
            };
        case DELETE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: { method: 'DELETE' },
            };
        case DELETE_MANY:
            const query = {
                ids: params.ids,
            };
            return {
                url : `${API_URL}/${resource}?${stringify(query)}`,
                options: { method: 'DELETE' },
            };
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};


const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const { headers, json } = response;
    switch (type) {
        case GET_LIST:
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
            };
        case CREATE:
            return { data: { ...params.data, id: json.id } };
        default:
            return { data: json };
    }
};


export default (type, resource, params) => {
    const { fetchJson } = fetchUtils;
    const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
    if (options !== undefined) {
        options.headers = new Headers({ Accept: 'application/json' });
        const token = localStorage.getItem('token');
        options.headers.set('Authorization', `Bearer ${token}`);
    }
    return fetchJson(url, options)
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};