/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
import React from 'react';
import {Admin, Resource} from 'react-admin';
import CustomLayout from "./theme/CustomLayout";
import dataProvider from "./dataProvider/dataProvider";
import {BubbleChart, Feedback, Group, LibraryBooks, LocalPizza, Restaurant} from '@material-ui/icons';
import {MenuList} from "./menus/menuList";
import {MenuEdit} from "./menus/menuEdit";
import {NutrientList} from "./nutrients/nutrientList";
import {NutrientEdit} from "./nutrients/nutrientEdit";
import {FoodList} from "./foods/foodList";
import {FoodEdit} from "./foods/foodEdit";
import {NutrientCreate} from "./nutrients/nutrientCreate";
import authProvider from "./authProvider/authProvider";
import {CustomLoginPage} from "./theme/CustomLoginPage";
import {FoodCreate} from "./foods/foodCreate";
import {MenuCreate} from "./menus/menuCreate";
import {createHashHistory} from 'history';
import {UserList} from "./users/userList";
import { Route } from 'react-router-dom';
import {PasswordReseter} from "./authProvider/passwordReseter";
import {TipList} from "./tips/tipList";
import {TipCreate} from "./tips/tipCreate";
import {TipEdit} from "./tips/tipEdit";
import {BlogPostList} from "./blogPosts/blogPostList";
import {BlogPostCreate} from "./blogPosts/blogPostCreate";
import {BlogPostEdit} from "./blogPosts/blogPostsEdit";
import {RESET_PASSWORD_ROUTE} from "./constants/ROUTES";
import addImageHandler from "./dataProvider/addImageHandler";
import { BrowserRouter, Switch } from 'react-router-dom';

const history = createHashHistory();
const uploadCapableDataProvider = addImageHandler(dataProvider);


function App() {
    return (
        <BrowserRouter >
            <Switch>
                <Route exact path={RESET_PASSWORD_ROUTE} component={PasswordReseter} />
            </Switch>
            <Admin appLayout={CustomLayout} dataProvider={uploadCapableDataProvider} authProvider={authProvider}
                   loginPage={CustomLoginPage} history={history} customRoutes={[
                <Route
                    exact
                    path={RESET_PASSWORD_ROUTE}
                    render={() => <PasswordReseter/>}
                    noLayout
                />
            ]}>
                <Resource name="menus" list={MenuList} edit={MenuEdit} icon={Restaurant} create={MenuCreate} context=""
                          options={""}/>
                <Resource name="foods" list={FoodList} edit={FoodEdit} create={FoodCreate} icon={LocalPizza} context=""
                          options={""}/>
                <Resource name="nutrients" list={NutrientList} edit={NutrientEdit} create={NutrientCreate}
                          icon={BubbleChart} context="" options={""}/>
                <Resource name="blogPosts" list={BlogPostList} edit={BlogPostEdit} create={BlogPostCreate}
                          icon={LibraryBooks} context="" options={{label: 'Blog'}}/>
                <Resource name="tips" list={TipList} edit={TipEdit} create={TipCreate}
                          icon={Feedback} context="" options={""}/>
                <Resource name="users" list={UserList} edit={NutrientEdit}
                          icon={Group} context="" options={""}/>
            </Admin>
        </BrowserRouter>
    );
}

export default App;
