/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
import React, {Component, Fragment} from "react";
import {CURRENT_USER_ENDPOINT, USER_CHANGE_PASSWORD_ENDPOINT} from "../constants/ENDPOINTS";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import './passwordReseter.css'
import {Link} from "react-router-dom";

const getChangePasswordAction = (email, password) => {
    return (
        {
            endpoint: USER_CHANGE_PASSWORD_ENDPOINT,
            body: {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email: email, password: password})
            }
        }
    )
};

const currentUserAction = {
    endpoint: CURRENT_USER_ENDPOINT,
    body: {

        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
    }
};


export class PasswordReseter extends Component {

    state = {
        loading: true,
        userEmail: '',
        error: false,
        msg: '',

        responseMsg: '',
        showResponseMsg: false,
        animationFlag: true,
        submitting: false,
    };

    flagHandler = () => {
        setTimeout(() => {
            this.setState({
                animationFlag: false
            })
        }, 4000)
    };


    componentDidMount() {
        fetch(currentUserAction.endpoint, currentUserAction.body)
        // Parse response
            .then(response => {
                if (response.status === 400) {
                    this.setState({error: true})
                }
                return response.json()
            })
            // Set data
            .then(data => {
                if (this.state.error) {
                    this.setState({msg: data.message})
                } else {
                    this.setState({userEmail: data.email})
                }
                this.setState({loading: false})
            })
            // Error
            .catch(error => {
                this.setState({error: error})
            });
    }

    handleSubmit = (values) => {
        this.setState({submitting: true});
        let changePasswordAction = getChangePasswordAction(values.email, values.password);

        fetch(changePasswordAction.endpoint, changePasswordAction.body)
        // Parse response
            .then(response => {
                if (response.status === 400) {
                    this.setState({error: true})
                }
                return response.json()
            })
            // Set data
            .then(data => {
                this.setState({responseMsg: data.message});
                this.flagHandler();
                this.setState({submitting: false});
            })
            // Error
            .catch(error => {
                this.setState({error: error});
            });
    };

    render() {
        return (
            <div className='form-wrapper'>
                {this.state.loading
                    ? <Fragment>Loading...</Fragment>
                    : <Formik
                        initialValues={{email: this.state.userEmail, password: '', passwordAgain: ''}}
                        validate={values => {
                            let errors = {};
                            const password = values.password;
                            const passwordAgain = values.passwordAgain;

                            if (!password) {
                                errors.password = 'Error: Password required';
                            } else if (password.length < 8) {
                                errors.password = 'Error: Password must be at least 8 letter long';
                            } else if (password !== passwordAgain) {
                                errors.password = 'Error: Passwords does not match';
                            }
                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            this.handleSubmit(values)
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form className='form'>
                                <div className='form-line'>
                                    <div className='label'>E-mail:</div>
                                    <Field disabled type="email" name="email"/>

                                </div>
                                <div className='form-line'>
                                    <div className='label'>New password:</div>
                                    <Field type="password" name="password" className='field'/>
                                </div>

                                <div className='form-line'>
                                    <div className='label'>New password (again):</div>
                                    <Field type="password" name="passwordAgain" className='field'/>
                                </div>

                                <button type="submit" disabled={this.state.submitting} className='form-line button'>
                                    Change password
                                </button>
                                <ErrorMessage className='err-msg form-line' name="password" component="div"/>


                                {/* Submitting form */}
                                {this.state.submitting
                                    ? <div>Changing password...</div>
                                    : <Fragment/>
                                }

                                {/* Response msg */}
                                {
                                    this.state.responseMsg !== '' && this.state.animationFlag
                                        ? <div className={this.state.error ? 'form-line err-msg' : ' form-line ok-msg'}>
                                            {this.state.responseMsg}
                                        </div>
                                        : <Fragment/>
                                }
                                <Link className='back-link' to='/users'>&#8592; Back</Link>
                            </Form>
                        )}
                    </Formik>
                }
            </div>
        )
    }


}