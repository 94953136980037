/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
import React, {Fragment} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    GET_MANY,
    maxValue,
    minValue,
    number,
    NumberInput,
    required,
    TextInput,
    SimpleForm,
    SimpleFormIterator,
} from 'react-admin'
import {Title} from "../shared/Title";
import {CustomToolbarEdit} from "../shared/CustomToolbarEdit";
import dataProvider from "../dataProvider/dataProvider";
import {adjustFoodMenusByDays, adjustFoodMenusByFoodsPerDay} from "./foodMenuHandlers";
import {checkIfCookerNeeded, foodNameEnum, getFoodLabel, getFoodName} from "./utils";


export class MenuEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            choices: {},

            defaultDays: 3,
            defaultFoodsPerDay: 3,
            defaultTrekDifficulty: 2
        }
    }


    componentDidMount() {
        dataProvider(GET_MANY, 'foods', this.state.filter)
            .then((data) => {
                this.setState({choices: this.separateFoodChoicesByFoodInDayTags(data.data)})

            })
    }

    separateFoodChoicesByFoodInDayTags = (allFoodChoices) => {
        let choices = {};
        choices[foodNameEnum.ALL] = allFoodChoices; // all foods
        choices[foodNameEnum.BREAKFAST] = allFoodChoices.filter(item => item.breakfastTag === true);
        choices[foodNameEnum.DINNER] = allFoodChoices.filter(item => item.dinnerTag === true);
        choices[foodNameEnum.LUNCH] = allFoodChoices.filter(item => item.lunchTag === true);
        choices[foodNameEnum.SNACK] = allFoodChoices.filter(item => item.snackTag === true);
        return choices;
    };

    render() {
        return (
            <Edit title={<Title name={'Menu'}/>} {...this.props}>
                <SimpleForm toolbar={<CustomToolbarEdit/>} redirect='/menus'>
                    <TextInput disabled source="id"/>
                    <TextInput source="name" validate={[required()]}/>
                    <FormDataConsumer>
                        {({formData}) =>
                            <Fragment>
                                <NumberInput source="days"
                                             defaultValue={this.state.defaultDays}
                                             validate={[required(), number(), minValue(1), maxValue(7)]}
                                             inputProps={{min: 1, max: 7}}
                                             className='inputWidthInlineFlex'
                                             onChange={event => {
                                                 adjustFoodMenusByDays(formData, event.target.value);
                                                 this.setState({defaultDays: event.target.value}) // hack to force re-render
                                             }}/>
                                <NumberInput source="foodsPerDay"
                                             defaultValue={this.state.defaultFoodsPerDay}
                                             className='inputWidthInlineFlex'
                                             validate={[required()]}
                                             inputProps={{min: 1, max: 5}}
                                             onChange={event => {
                                                 adjustFoodMenusByFoodsPerDay(formData, event.target.value);
                                                 this.setState({defaultFoodsPerDay: event.target.value}) // hack to force re-render
                                             }}/>
                                <NumberInput source="trekDifficulty"
                                             defaultValue={this.state.defaultTrekDifficulty}
                                             validate={[required(), number(), minValue(1), maxValue(3)]}
                                             className='inputWidthInlineFlex'
                                             inputProps={{min: 1, max: 3}}/>
                            </Fragment>
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({
                              formData,
                          }) => {
                            formData["isCookerNeeded"] = checkIfCookerNeeded(formData, this.state.choices[foodNameEnum.ALL]);
                            return <BooleanInput label="Is cooker needed?" source="isCookerNeeded"/>
                        }
                        }
                    </FormDataConsumer>
                    <ArrayInput source="foodMenus">
                        <SimpleFormIterator disableRemove disableAdd>
                            <FormDataConsumer>
                                {({
                                      formData,
                                      getSource,
                                      scopedFormData
                                  }) => {
                                    const foodInDayName = getFoodName(scopedFormData, formData);
                                    const label = getFoodLabel(scopedFormData, formData);
                                    return (
                                        <AutocompleteInput
                                            allowEmpty={true}
                                            source={getSource("foodId")}
                                            formClassName={"arrayInputItemText"}
                                            choices={this.state.choices[foodInDayName]}
                                            optionText="name"
                                            label={label}
                                        />
                                    )
                                }
                                }
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <TextInput disabled source="user.email" label="Author"/>
                </SimpleForm>
            </Edit>
        )
    }

}