/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
export const reCountEnergyAndWeight = (formData, choices) =>{
    let energySum = 0;
    let weightSum = 0;
    let foodNutrients = formData.foodNutrients;
    if (foodNutrients !== undefined){
        for (let i=0; i < foodNutrients.length; i++){
            let foodNutrient = foodNutrients[i];
            if (foodNutrient.nutrient === undefined || foodNutrient.count === undefined)
                continue;
            let nutrient = choices.find(value => value.id === foodNutrient.nutrient.id);

            // Hack
            if (nutrient === undefined)
                return;

            weightSum += nutrient.unitWeight * foodNutrient.count;

            // Energy
            let energyPerGram = nutrient.energy / 100.0; // Nutrient.Energy is per 100g
            let energyPerUnit = energyPerGram * nutrient.unitWeight; // Weight is in grams
            energySum += Math.round(energyPerUnit * foodNutrient.count); // X units of nutrient used in food
        }
    }

    formData.weight = weightSum;
    formData.energy = energySum
};