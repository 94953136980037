/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
import React, {Fragment} from 'react';
import {
    ArrayInput,
    BooleanInput,
    Create,
    FormDataConsumer,
    GET_MANY,
    ImageField,
    ImageInput,
    minValue,
    number,
    AutocompleteInput,
    NumberInput,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
} from 'react-admin'
import {Title} from "../shared/Title";
import '../App.css'
import dataProvider from "../dataProvider/dataProvider";
import {reCountEnergyAndWeight} from "./foodUtils";
import {CustomToolBarCreate} from "../shared/CustomToolbarEdit";
import {convertUnitName} from "./unitNameConvertor";


export class FoodCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            choices: []
        }
    }

    componentDidMount() {
        dataProvider(GET_MANY, 'nutrients', this.state.filter)
            .then((data) => {
                this.setState({choices: data.data})
            })
    }

    render() {
        return (
            <Fragment>
                <Create title={<Title name={'Food'}/>}  {...this.state.props}>
                    <SimpleForm toolbar={<CustomToolBarCreate/>} redirect='/foods'>
                        <TextInput source="name" validate={required()}/>
                        <BooleanInput source="isCookerNeeded" validate={required()} defaultValue={true}/>
                        <FormDataConsumer>
                            {() =>
                                <Fragment>
                                    <TextInput disabled  source="energy" label='Energy (kJ)' className={"energyWeightInput"}/>
                                    <TextInput disabled source="weight" label='Weight (g)' className={"energyWeightInput"}/>
                                </Fragment>
                            }
                        </FormDataConsumer>
                        <NumberInput source="price" defaultValue={0}/>
                        <BooleanInput source="breakfastTag" defaultValue={true} formClassName={"inputWidthInlineFlex"}/>
                        <BooleanInput source="lunchTag" defaultValue={true} formClassName={"inputWidthInlineFlex"}/>
                        <BooleanInput source="dinnerTag" defaultValue={true} formClassName={"inputWidthInlineFlex"}/>
                        <BooleanInput source="snackTag" defaultValue={true} formClassName={"inputWidthInlineFlex"}/>
                        <FormDataConsumer>
                            {({formData}) =>
                                <ArrayInput source="foodNutrients"
                                            onChange={reCountEnergyAndWeight(formData, this.state.choices)}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({
                                                  scopedFormData,
                                                  getSource
                                              }) => {
                                                if (scopedFormData !== undefined){
                                                    const nutrientId = scopedFormData.nutrientId;
                                                    if (nutrientId !== undefined) {
                                                        const nutrient = this.state.choices.find(nutrient => nutrient.id === nutrientId);
                                                        var unitName = convertUnitName(nutrient.unitName);
                                                        var unitWeight = nutrient.unitName === 0 ?  nutrient.unitWeight : undefined;
                                                    }
                                                }

                                                return <Fragment>
                                                    <AutocompleteInput
                                                        source={getSource('nutrientId')}
                                                        validate={required()}
                                                        formClassName={"arrayInputItemText"}
                                                        choices={this.state.choices}
                                                        optionText="czName"
                                                        label='Nutrient name'
                                                    />
                                                    <NumberInput
                                                        className={"arrayInputNumber"}
                                                        label="Count"
                                                        source={getSource('count')}
                                                        inputProps={{min: 1}}
                                                        defaultValue={1}
                                                        validate={[required(), number(), minValue(1)]}
                                                    />
                                                    <span>{unitName} {unitWeight === undefined  ? "" : "(1 unit = " + unitWeight + "g)"}
                                                </span>
                                                </Fragment>
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            }
                        </FormDataConsumer>
                        <ImageInput source="imageBase64" label="Food picture" accept="image/*">
                            <ImageField source="src" title="title"/>
                        </ImageInput>
                        <BooleanInput source="approved" defaultValue={true} />
                    </SimpleForm>
                </Create>
            </Fragment>
        )
    }
}







