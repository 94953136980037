/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
import React, {Fragment} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Create,
    FormDataConsumer,
    GET_MANY,
    maxValue,
    minValue,
    number,
    NumberInput,
    required,
    SimpleForm,
    SimpleFormIterator, TextInput,
} from 'react-admin'
import {Title} from "../shared/Title";
import {CustomToolBarCreate} from "../shared/CustomToolbarEdit";
import dataProvider from "../dataProvider/dataProvider";
import {isEmpty} from "lodash";
import {adjustFoodMenusByDays, adjustFoodMenusByFoodsPerDay} from "./foodMenuHandlers";
import {getGuid} from "../shared/Utils";

import {checkIfCookerNeeded, foodNameEnum, getFoodLabel, getFoodName} from "./utils";


export class MenuCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            choices: {},

            defaultDays: 3,
            defaultFoodsPerDay: 3,
            defaultTrekDifficulty: 2,

            foodMenus: this.preFillFoodMenus(9)
        };

    }

    componentDidMount() {
        dataProvider(GET_MANY, 'foods', this.state.filter)
            .then((data) => {
                this.setState({choices: this.separateFoodChoicesByFoodInDayTags(data.data)})
            })
        //.then(() => this.setState({foodMenus: this.preFillFoodMenus(this.state.defaultDays * this.state.defaultFoodsPerDay)}))
    }


    separateFoodChoicesByFoodInDayTags = (allFoodChoices) => {
        let choices = {};
        choices[foodNameEnum.ALL] = allFoodChoices; // all foods
        choices[foodNameEnum.BREAKFAST] = allFoodChoices.filter(item => item.breakfastTag === true);
        choices[foodNameEnum.DINNER] = allFoodChoices.filter(item => item.dinnerTag === true);
        choices[foodNameEnum.LUNCH] = allFoodChoices.filter(item => item.lunchTag === true);
        choices[foodNameEnum.SNACK] = allFoodChoices.filter(item => item.snackTag === true);
        return choices;
    };

    preFillFoodMenus = (arrayLength) => {
        let foodMenus = [];
        for (let i = 0; i < arrayLength; i++) {
            foodMenus.push({id: getGuid(), foodInTrekPosition: i, foodId: null})
        }
        return foodMenus
    };


    render() {
        return (
            <Create title={<Title name={'Menu'}/>} {...this.props}>
                <SimpleForm toolbar={<CustomToolBarCreate/>} redirect='/menus'>
                    <TextInput source="name" validate={[required()]}/>
                    <FormDataConsumer>
                        {({formData}) =>
                            <Fragment>
                                <NumberInput source="days"
                                             defaultValue={this.state.defaultDays}
                                             validate={[required(), number(), minValue(1), maxValue(7)]}
                                             inputProps={{min: 1, max: 7}}
                                             className='inputWidthInlineFlex'
                                             onChange={event => {
                                                 adjustFoodMenusByDays(formData, event.target.value);
                                                 this.setState({defaultDays: event.target.value}) // hack to force re-render
                                             }}/>
                                <NumberInput source="foodsPerDay"
                                             defaultValue={this.state.defaultFoodsPerDay}
                                             className='inputWidthInlineFlex'
                                             validate={[required()]}
                                             inputProps={{min: 1, max: 5}}
                                             onChange={event => {
                                                 adjustFoodMenusByFoodsPerDay(formData, event.target.value);
                                                 this.setState({defaultFoodsPerDay: event.target.value}) // hack to force re-render
                                             }}/>
                                <NumberInput source="trekDifficulty"
                                             defaultValue={this.state.defaultTrekDifficulty}
                                             validate={[required(), number(), minValue(1), maxValue(3)]}
                                             className='inputWidthInlineFlex'
                                             inputProps={{min: 1, max: 3}}/>
                            </Fragment>
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({
                              formData,
                          }) => {
                            formData["isCookerNeeded"] = checkIfCookerNeeded(formData, this.state.choices[foodNameEnum.ALL]);
                            return <BooleanInput label="Is cooker needed?" source="isCookerNeeded"/>
                        }
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({
                              formData
                          }) => {
                            if (isEmpty(formData.foodMenus)) {
                                formData.foodMenus = this.state.foodMenus
                            }
                            return (
                                <ArrayInput source="foodMenus" allowEmpty>
                                    <SimpleFormIterator disableRemove disableAdd>
                                        <FormDataConsumer>
                                            {({
                                                  formData,
                                                  scopedFormData,
                                                  getSource
                                              }) => {
                                                if (!isEmpty(scopedFormData)) {
                                                    var foodInDayName = getFoodName(scopedFormData, formData);
                                                    var label = getFoodLabel(scopedFormData, formData);
                                                }
                                                return (
                                                    <AutocompleteInput
                                                        allowEmpty={true}
                                                        source={getSource('foodId')}
                                                        formClassName={"arrayInputItemText"}
                                                        choices={this.state.choices[foodInDayName]}
                                                        optionText="name"
                                                        label={label}
                                                        inputValueMatcher={() => null}
                                                    />
                                                )
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>

                            )
                        }
                        }
                    </FormDataConsumer>
                </SimpleForm>
            </Create>
        )
    }


}









