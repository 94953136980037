/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    Filter,
    BooleanInput,
    TextInput,
    BooleanField,
} from 'react-admin';
import {NameSort} from "../shared/NameSort";
import {PostBulkActionButtons} from "../shared/BulkActions";

const FoodFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
        <BooleanInput source="hasNutrients" defaultValue={false}/>
        <BooleanInput source="breakfastTag" defaultValue={false}/>
        <BooleanInput source="lunchTag" defaultValue={false}/>
        <BooleanInput source="snackTag" defaultValue={false}/>
        <BooleanInput source="approved" defaultValue={false}/>
        <NumberInput source="energy" />
        <NumberInput source="weight" />
    </Filter>
);

export const FoodList = props => (
    <List {...props} filters={ <FoodFilter/> } sort={NameSort}  bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid rowClick="edit">
            <TextField source="id" sortable={false}/>
            <TextField source="name" />
            <BooleanField source="isCookerNeeded" />
            <NumberField source="price" />
            <NumberField source="stars" />
            <BooleanField source="breakfastTag" />
            <BooleanField source="lunchTag" />
            <BooleanField source="dinnerTag" />
            <BooleanField source="snackTag" />
            <BooleanField source="approved" />
            <NumberField source="energy" />
            <NumberField source="weight" />
        </Datagrid>
    </List>
);