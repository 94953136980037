/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const applyToResource = ['foods', 'blogPosts', 'nutrients'];

const addImageHandler = requestHandler => (type, resource, params) => {
    if ((type === 'CREATE' || type === 'UPDATE' ) && (applyToResource.includes(resource))) {
        if (params.data.imageBase64) {
            const newPictures = [params.data.imageBase64];

            return Promise.all(newPictures.map(convertFileToBase64))
                .then(transformedNewPictures => {

                    return requestHandler(type, resource, {
                            ...params,
                            data: {
                                ...params.data,
                                imageBase64: transformedNewPictures[0]
                            },
                        }
                    )
                    }
                );
        }
    }
    return requestHandler(type, resource, params);
};

export default addImageHandler;