/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
import { Title } from "../shared/Title";
import { CustomToolBarCreate } from "../shared/CustomToolbarEdit";
import React from "react";
import {
    Create,
    FormDataConsumer,
    minValue,
    number,
    ImageField,
    ImageInput,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import { unitNameChoices } from "./unitNameChoices";


export const NutrientCreate = (props) => (
    <Create {...props} title={<Title name={'New Nutrient'} />}>
        <SimpleForm toolbar={<CustomToolBarCreate />} redirect='/nutrients'>
            <TextInput source="czName" validate={required()} />
            <TextInput source="name" label="En name" validate={required()} />
            <NumberInput source="energy" label="Energy (KJ)" inputProps={{ min: 1 }} validate={[required(), number(), minValue(0)]} />
            <NumberInput source="fat" label="Fat (g)" inputProps={{ min: 1 }} validate={[required(), number(), minValue(0)]} />
            <NumberInput source="carbohydrate" label="Carbohydrate (g)" inputProps={{ min: 1 }} validate={[required(), number(), minValue(0)]} />
            <NumberInput source="fiber" label="Fiber (g)" inputProps={{ min: 1 }} validate={[required(), number(), minValue(0)]} />
            <NumberInput source="sugar" label="Sugar (g)" inputProps={{ min: 1 }} validate={[required(), number(), minValue(0)]} />
            <NumberInput source="protein" label="Protein (g)" inputProps={{ min: 1 }} validate={[required(), number(), minValue(0)]} />
            <NumberInput source="salt" label="Salt (g)" inputProps={{ min: 1 }} validate={[required(), number(), minValue(0)]} />
            <FormDataConsumer>
                {({ formData }) => {
                    if (formData.unitName !== undefined && formData.unitName === 1) {
                        formData.unitWeight = 1; // Portion has default unit weight 1g
                        return <NumberInput disabled source="unitWeight" label="Unit weight (g)" />
                    } else {
                        return <NumberInput source="unitWeight" label="Unit weight (g)" inputProps={{ min: 1 }}
                            validate={[required(), number(), minValue(0)]} />
                    }
                }
                }
            </FormDataConsumer>
            <SelectInput source="unitName" choices={unitNameChoices} defaultValue={0} />
            <ImageInput source="imageBase64" label="Nutrient picture" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);