/*
 Copyright 2019 Karel Jiránek

 Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and limitations under the License.
*/
// eslint-disable-next-line no-unused-vars
import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    Filter,
    TextInput,
    SelectField
} from 'react-admin';
import {NameSort} from "../shared/NameSort";
import {PostBulkActionButtons} from "../shared/BulkActions";
import {unitNameChoices} from "./unitNameChoices";


const NutrientsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="nutrientNameFilterCZ_EN" alwaysOn />
    </Filter>
);

export const NutrientList = props => (
    <Fragment>
        <List {...props} filters={ <NutrientsFilter/> } sort={NameSort}  bulkActionButtons={<PostBulkActionButtons />}>
            <Datagrid rowClick="edit">
                <TextField source="id" sortable={false}/>
                <TextField source="name" />
                <TextField source="czName" />
                <NumberField source="energy" />
                <NumberField source="fat" />
                <NumberField source="carbohydrate" />
                <NumberField source="fiber" />
                <NumberField source="sugar" />
                <NumberField source="protein" />
                <NumberField source="salt" />
                <NumberField source="unitWeight" label='Unit weight**'/>
                <SelectField source="unitName" sortable={false} choices={unitNameChoices}/>
            </Datagrid>
        </List>
        <i>* Values for nutrients are counted per 100g.</i>
        <i>** Unit weight is weight of 1 piece or portion.</i>
    </Fragment>
);